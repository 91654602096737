import request from '@/util/request'

// 查询商品订单列表
export function listOrder(query) {
  return request({
    url: '/system/order/list',
    method: 'get',
    params: query
  })
}

// 查询符合条件的所有商品订单
export function listAllOrder(query) {
  return request({
    url: '/system/order/listAll',
    method: 'get',
    params: query
  })
}


// 查询商品订单列表
export function listOuterOrder(query) {
  return request({
    url: '/system/order/listOuter',
    method: 'get',
    params: query
  })
}

// 查询商品订单详细
export function getOrder(orderId) {
  return request({
    url: '/system/order/edit/' + orderId,
    method: 'get'
  })
}

// 新增商品订单
export function buyPublic(data) {
  return request({
    url: '/system/order/addPublic',
    method: 'post',
    data: data
  })
}

// 修改商品订单
export function updateOrder(data) {
  return request({
    url: '/system/order',
    method: 'put',
    data: data
  })
}

// 删除商品订单
export function delOrder(orderId) {
  return request({
    url: '/system/order/' + orderId,
    method: 'delete'
  })
}

// 浏览商品订单
export function browseOrder(orderId) {
  return request({
    url: '/system/order/browsePublic/' + orderId,
    method: 'get'
  })
}

//附件事件
