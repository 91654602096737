import request from '@/util/request'

// 查询在售商品列表
export function listProduct(query) {
  return request({
    url: '/system/product/list',
    method: 'get',
    params: query
  })
}

// 查询符合条件的所有在售商品
export function listAllProduct(query) {
  return request({
    url: '/system/product/listAllPublic',
    method: 'get',
    params: query
  })
}


// 查询在售商品列表
export function listOuterProduct(query) {
  return request({
    url: '/system/product/listOuter',
    method: 'get',
    params: query
  })
}

// 查询在售商品详细
export function getProduct(productId) {
  return request({
    url: '/system/product/edit/' + productId,
    method: 'get'
  })
}

// 新增在售商品
export function insertProduct(data) {
  return request({
    url: '/system/product',
    method: 'post',
    data: data
  })
}

// 修改在售商品
export function updateProduct(data) {
  return request({
    url: '/system/product',
    method: 'put',
    data: data
  })
}

// 删除在售商品
export function delProduct(productId) {
  return request({
    url: '/system/product/remove/' + productId,
    method: 'get'
  })
}

// 浏览在售商品
export function browseProduct(productId) {
  return request({
    url: '/system/product/browsePublic/' + productId,
    method: 'get'
  })
}

//附件事件
