import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../components/Layout.vue';
import Index from '../views/Index'
import BuyProduct from "@/views/BuyProduct";
import BuyProductResult from "@/views/BuyProductResult";
const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: Index
      },
    ]
  },
  {
    path: '/buyProduct',
    name: 'BuyProduct',
    component: BuyProduct,
  },
  {
    path: '/buyProductResult',
    name: 'BuyProductResult',
    component: BuyProductResult,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
