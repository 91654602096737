import { createApp } from 'vue'
import Vant from 'vant'
import * as Vants from 'vant'
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import locale from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { getFileName, getFileUrl } from "@/util/lixy";

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)
app.config.globalProperties.getFileName = getFileName;
app.config.globalProperties.getFileUrl = getFileUrl
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
for (const [key, component] of Object.entries(Vants)) {
  app.component(key, component)
}
app.use(ElementPlus, { locale });
app.use(Vant, { locale });
app.use(store).use(router).mount('#app')
