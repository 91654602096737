<template>
  <div style="margin:0;padding:0;">
    <div v-if="seen" style="margin:0;padding:0;">
      <div class="header">
      <div class="logo_img" @click="logoClick">
        <div style="display:flex; align-items: center; float:left; height: 65px;">
          <div class="header-logo">
            <img id="header-img" src="../assets/logo.png" >
          </div>
        </div>
        <div style="display:flex; float:left; height: 65px;">
          <div class="header-important">
            <div class="company_name">杭州阿尔法伽信息科技有限公司</div>
            <div class="header-title company_name">www.alphajia.com</div>
          </div>
        </div>
      </div>
      </div>
      <div style="width:99%;margin: 0px auto;">
        <div style="height:1px;padding:0;background-color:#999;">
        </div>
      </div>
      <el-row >
        <el-col :xs="24" :sm="24" style="text-align: center;margin: 100px auto;">
          <div><img :src="company.qrcode" style="width: 100px;height: 100px;border: 0"/></div>
          <div class="footer_font">请用手机扫上面的二维码进入移动版购买服务</div>
        </el-col>
      </el-row>
    </div>
    <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
    <div v-else >
      <el-row  class="header">
        <el-col :span="24">
          <div class="logo_img" @click="logoClick">
            <div style="display:flex; align-items: center; float:left; height: 65px;">
              <div class="header-logo">
                <img id="header-img" src="../assets/logo.png" >
              </div>
            </div>
            <div style="display:flex; float:left; height: 65px;">
              <div class="header-important">
                <div class="company_name">杭州阿尔法伽信息科技有限公司</div>
                <div class="header-title company_name">www.alphajia.com</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row  class="header">
        <el-col :span="24">
          <van-nav-bar
              title="交易结果"
              @click-right="onClickSearch"
          >
            <template #right>
              <van-icon name="search" size="18" />
              查询订单
            </template>
          </van-nav-bar>
        </el-col>
      </el-row>
      <van-cell-group>
        <van-cell title="交易结果" />
        <van-cell title="商品价格" :value="order.unitPrice+'元'" />
        <van-cell title="商品数量" value="1"/>
        <van-cell title="已付金额" :value="order.payedFee+'元'"/>
        <van-cell title="姓名" :value="order.guest" />
        <van-cell title="手机号" :value="order.telephone" />
        <van-cell title="订单号" :value="order.memo" />
        <van-cell title="提示" value="请用交易手机联系客服(19957130267)并提供订单号完成剩下的交易" />
      </van-cell-group>
      <van-button type="danger" block @click="$router.push('/index')">结束交易</van-button>
    </div>
    <div style="width: 90%;margin: 20px auto;">
      <div style="height:1px;padding:0;background-color:#999;">
      </div>
    </div>
    <div style="width: 90%;margin: 20px auto;">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="12">
          <p class="footer_font">邮箱：mailto:hzalphajia@163.com</p>
          <div style="margin: auto;" class="footer_font">
            互联网ICP备案：浙ICP备2024063599号
          </div>
          <div style="margin: auto;" class="footer_font">
            Copyright © 2024 杭州阿尔法伽信息科技有限公司版权所有
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" style="text-align: center;margin-bottom: 15px;">
          <div><img :src="company.qrcodePublic" style="width: 100px;height: 100px;border: 0"/></div>
          <div class="footer_font">关注杭州阿尔法伽信息科技公众号</div>
        </el-col>
      </el-row>
    </div>
    <div id="back_to_top" ref="btn" @click="backTop" style="display: block;">
      <p style="font-size: 0.625em; font-weight: bold">TOP</p>
      <img src="../assets/launch.png" style="height: 45px;width: 35px" />
    </div>
  </div>
</template>

<script>
  import {browseOrder, listAllOrder} from "@/api/order";
  import {buyPublic} from "@/api/order";
  import modal from '@/util/modal'

  export default {
    name: "Layout",
    data() {
      return {
        countdown: 0, // 倒计时时间
        intervalId: 0, // 存储定时器ID
        totalTime: 60, // 总倒计时时间，如60秒
        activeIndex: '1',
        company:{
          qrcodePublic: require("../assets/qr_public.jpg"),
          qrcode: require("../assets/qrcode_alphajia.png"),
        },
        scroll: '',
        timer: '',
        seen: true,
        currentMenu:'首页',
        serviceName: '',
        order: {
          orderId: null,
        },
      };
    },
    methods: {
      onClickLeft(){
        history.back()
      },
      onClickSearch(){
        history.back()
      },
      loadOrder(){
        browseOrder(this.$route.query.id).then(res=>{
          if(res.code == 200){
            this.order = res.data;
            console.log("order:", this.order)
          }
        })
      },
      backTop(){ //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
        this.timer = setInterval(() => {
          let osTop = document.documentElement.scrollTop || document.body.scrollTop;
          let ispeed = Math.floor(-osTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
          if (osTop === 0) {
            clearInterval(this.timer)
          }
        }, 30)
      },
      menuSeen(){
        // 屏幕尺寸
        let screenHeight=document.body.clientWidth;
        if(screenHeight>415){
          this.seen=true;
        }else{
          this.seen=false;
        }
      },
      logoClick(){
        this.$router.push({name: "Index"});
        this.currentMenu='首页';
      }
    },
    mounted() {
      this.menuSeen()
      this.loadOrder()
    },
  }
</script>

<style scoped>
  body{
    overflow-x:hidden;
    overflow-y:hidden;
  }
  .logo_img{
    float:left;
    width: 30%;
    cursor: pointer;
  }
  #back_to_top{
    position: fixed;
    bottom: 50px;
    right: 30px;
    cursor: pointer
  }
  .header-title{
    font-size: 1em;
  }
  .company_name{
    font-weight: normal;
    font-size: 1em;
  }
  .footer_font{
    font-size: 1em;
  }
  #menu_index{
    float: left; width: 50%;height: 100%
  }
  .header{
    margin: auto;
    width: 98%;
    height: 65px;
    /*background: #F56C6C;*/
  }
  @media screen and (max-device-width: 415px) {
    .logo_img{
      float:left;
      width: auto;
      cursor: pointer;
    }
    #back_to_top{
      position: fixed;
      bottom: 50px;
      right: 5px;
      cursor: pointer
    }
    .footer_font{
      font-size: 0.875em;
    }
    #menu_index{
      float: right;
      width: auto;
      height: 100%
    }
    .mobile_menu{

    }
  }
  #header-img{
    width: 50px;
    height: 50px;
    float: left;
    margin: auto;
  }
  .header-logo{
    height: 50px;
    width: 50px;
    align-self: center;
  }
  .header-important{
    float: left;
    margin-left: 6px;
    align-self: center;
  }

  .footer{
    display: flex;
    align-items: center;
    margin: 10px auto;
    width: 90%;
  }
  a{text-decoration: none;}
  a:visited{text-decoration: none;}
  a:hover {text-decoration: none;}
  a:active{text-decoration:none;}
</style>
