<template>
    <div>
        <div v-if="seen"  class="header">
            <div class="logo_img" @click="logoClick">
                <div style="display:flex; align-items: center; float:left; height: 65px;">
                    <div class="header-logo">
                        <img id="header-img" src="../assets/logo.png" >
                    </div>
                </div>
                <div style="display:flex; float:left; height: 65px;">
                    <div class="header-important">
                        <div class="company_name">杭州阿尔法伽信息科技有限公司</div>
                        <div class="header-title company_name">www.alphajia.com</div>
                    </div>
                </div>
            </div>
            <div id="menu_index">
                <!-- web端菜单导航 -->
                <el-menu :default-active="activeIndex" :ellipsis="false" class="el-menu-demo" menu-trigger="click" text-color="#333" style="height: 65px; border:0;" mode="horizontal" @select="handleSelect">
                  <el-menu-item index="1" class="header-title">首页</el-menu-item>
                  <el-menu-item index="2" class="header-title">公司介绍</el-menu-item>
                    <el-menu-item index="3" class="header-title">公司产品</el-menu-item>
                    <el-menu-item index="6" class="header-title">在售服务</el-menu-item>
                    <el-menu-item index="4" class="header-title">合作伙伴</el-menu-item>
                    <el-menu-item index="5" class="header-title">关于我们</el-menu-item>
                </el-menu>
            </div>
        </div>
        <!-- 手机端菜单导航 v-bind:style="{backgroundColor:'#409EFF'}" -->
        <div v-else >
            <el-row  class="header">
                <el-col :span="24">
                    <div class="logo_img" @click="logoClick">
                        <div style="display:flex; align-items: center; float:left; height: 65px;">
                            <div class="header-logo">
                                <img id="header-img" src="../assets/logo.png" >
                            </div>
                        </div>
                        <div style="display:flex; float:left; height: 65px;">
                            <div class="header-important">
                                <div class="company_name">杭州阿尔法伽信息科技有限公司</div>
                                <div class="header-title company_name">www.alphajia.com</div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row  class="header">
                <el-col :span="24">
                    <div style="width: 100%;height:100%;">
                        <van-tabs v-model:active="activeIndex" @click-tab="handleSelectMobile" shrink>
                            <van-tab title="首页"></van-tab>
                            <van-tab title="公司介绍"></van-tab>
                            <van-tab title="公司产品"></van-tab>
                            <van-tab title="在售服务"></van-tab>
                            <van-tab title="合作伙伴"></van-tab>
                            <van-tab title="关于我们"></van-tab>
                        </van-tabs>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="container" style="clear: both;">
            <router-view></router-view>
        </div>

        <div style="width: 90%;margin: 20px auto;">
            <div style="height:1px;padding:0;background-color:#999;">
            </div>
        </div>
        <div style="width: 90%;margin: 20px auto;">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12">
                    <p class="footer_font">邮箱：mailto:hzalphajia@163.com</p>
                    <div style="margin: auto;" class="footer_font">
                        互联网ICP备案：浙ICP备2024063599号
                    </div>
                    <div style="margin: auto;" class="footer_font">
                      Copyright © 2024 杭州阿尔法伽信息科技有限公司版权所有
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12" style="text-align: center;margin-bottom: 15px;">
                    <div><img :src="company.qrcode" style="width: 100px;height: 100px;border: 0"/></div>
                    <div class="footer_font">关注杭州阿尔法伽信息科技公众号</div>
                </el-col>
            </el-row>
        </div>
        <div id="back_to_top" ref="btn" @click="backTop" style="display: block;">
            <p style="font-size: 0.625em; font-weight: bold">TOP</p>
            <img src="../assets/launch.png" style="height: 45px;width: 35px" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "Layout",
        data() {
            return {
                activeIndex: '1',
                company:{
                    qrcode: require("../assets/qr_public.jpg"),
                },
                scroll: '',
                timer: '',
                seen: true,
                currentMenu:'首页',
            };
        },
        methods: {
            handleSelect(key) {
                switch (key){
                    case '1':
                        this.$router.push({name: "Index"});
                      window.location.hash = "#首页";
                        break;
                    case '2':
                      this.$router.push({name: "Index"});
                        window.location.hash = "#公司介绍";
                        break;
                    case '3':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#公司产品";
                        break;
                    case '4':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#合作伙伴";
                        break;
                    case '5':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#关于我们";
                        break;
                    case '6':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#在售服务";
                        break;
                }
            },
            handleSelectMobile({title}) {
                switch (title){
                    case '首页':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#首页";
                        this.currentMenu='首页';
                        break;
                    case '公司介绍':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#公司介绍";
                        this.currentMenu='公司介绍';
                        break;
                    case '公司产品':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#公司产品";
                        this.currentMenu='公司产品';
                        break;
                    case '合作伙伴':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#合作伙伴";
                        this.currentMenu='合作伙伴';
                        break;
                    case '关于我们':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#关于我们";
                        this.currentMenu='关于我们';
                        break;
                    case '在售服务':
                        this.$router.push({name: "Index"});
                        window.location.hash = "#在售服务";
                        this.currentMenu='在售服务';
                        break;
                }
            },
            handleScroll(){
                this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
              console.log(this.$refs,'--refs')
            },
            backTop(){ //当点击标签的时候,使用animate在200毫秒的时间内,滚到顶部
                this.timer = setInterval(() => {
                    let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                    let ispeed = Math.floor(-osTop / 5)
                    document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                    if (osTop === 0) {
                        clearInterval(this.timer)
                    }
                }, 30)
            },
          toOrder(){
            window.location.href = '/order/index'
          },
            menuSeen(){
                // 屏幕尺寸
                let screenHeight=document.body.clientWidth;
                if(screenHeight>415){
                    this.seen=true;
                }else{
                    this.seen=false;
                }
            },
            logoClick(){
                this.$router.push({name: "Index"});
                this.currentMenu='首页';
            }
        },
        mounted() {
            this.menuSeen()
        },
    }
</script>

<style scoped>
    .logo_img{
        float:left;
        width: 30%;
        cursor: pointer;
    }
    #back_to_top{
        position: fixed;
        bottom: 50px;
        right: 30px;
        cursor: pointer
    }
    .header-title{
        font-size: 1em;
    }
    .company_name{
        font-weight: normal;
        font-size: 1em;
    }
    .footer_font{
        font-size: 1em;
    }
    #menu_index{
        float: left; width: 50%;height: 100%
    }
    .header{
        margin: auto;
        width: 98%;
        height: 65px;
        /*background: #F56C6C;*/
    }
    @media screen and (max-device-width: 415px) {
        .logo_img{
            float:left;
            width: auto;
            cursor: pointer;
        }
        #back_to_top{
            position: fixed;
            bottom: 50px;
            right: 5px;
            cursor: pointer
        }
        .footer_font{
            font-size: 0.875em;
        }
        #menu_index{
            float: right;
            width: auto;
            height: 100%
        }
        .mobile_menu{

        }
    }
    #header-img{
        width: 50px;
        height: 50px;
        float: left;
        margin: auto;
    }
    .header-logo{
        height: 50px;
        width: 50px;
        align-self: center;
    }
    .header-important{
        float: left;
        margin-left: 6px;
        align-self: center;
    }

    .footer{
        display: flex;
        align-items: center;
        margin: 10px auto;
        width: 90%;
    }
    a{text-decoration: none;}
    a:visited{text-decoration: none;}
    a:hover {text-decoration: none;}
    a:active{text-decoration:none;}
</style>
