<template>
  <div>
    <el-carousel id="首页" :height="computeHeight">
      <el-carousel-item v-for="(item, index) in sliders" :key="index">
        <img :src="item.img" class="slider_img">
      </el-carousel-item>
    </el-carousel>
    <div id="公司介绍" class="solve head-label">
      <div style="text-align: center;" class="font_size_24">
        公司介绍
      </div>
    </div>
    <!-- 盒子 -->
    <div class="border_parent">
      <el-row>
        <el-col>
          <h3>
            杭州阿尔法伽信息科技有限公司：引领互联网数字化与人工智能的新纪元
          </h3>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司，作为互联网数字化与人工智能领域的领军企业，我们始终走在行业前沿，尤其在数字医疗和AI策略交易领域取得了显著成就。 </p>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司，自创立以来便深耕互联网数字化领域，特别在牙科诊所数字化行业中树立了卓越的标杆。早在2001年，我们便率先推出了一系列创新系统，如牙科诊所管理系统、病历管理系统和医药管理系统，为行业数字化进程奠定了坚实基础。多年来，我们紧跟行业脉搏，持续为众多牙科诊所和义齿加工企业提供卓越的软件解决方案，助力他们实现数字化升级。
          </p>
          <p class="fancy-text">
            凭借丰富的行业经验和广泛的合作伙伴网络，杭州阿尔法伽信息科技有限公司已成为软件运营与开发领域的领军者。我们始终以客户为中心，致力于为他们提供前沿、高效的软件解决方案和全方位的服务。无论是技术创新还是服务品质，我们都力求做到最好，成为您值得信赖的长期合作伙伴。
          </p>
          <h3>
            开发背景与团队实力
          </h3>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司拥有一支由浙江大学和浙江科技大学资深开发者组成的顶尖技术团队。凭借深厚的专业背景和丰富的实战经验，在软件开发领域持续创新，确保了我们的产品，如牙快约系统，始终保持高度的稳定性和可靠性。这个团队汇聚了众多博士和硕士研究生，他们不仅具备扎实的专业知识，还拥有丰富的项目实践经验，为系统的开发和优化提供了强大的技术支持。正是这样的专业团队和卓越实力，让我们能够为客户提供稳定可靠的技术支持，成为他们值得信赖的合作伙伴。 </p>
        </el-col>
      </el-row>
    </div>
    <div id="公司产品" class="solve head-label">
      <div style="text-align: center;" class="font_size_24">
        公司产品
      </div>
    </div>
    <!-- 盒子 -->
    <div class="border_parent">
      <el-row>
        <el-col>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司，作为行业内的创新领导者，始终致力于研发和推广前沿的科技产品，以满足不同领域的需求。
          </p>
          <p class="fancy-text">
            以下是我们的几款明星产品：
          </p>
          <h3>
            牙快约：智能牙科助手
          </h3>
          <p class="fancy-text">
            牙快约是专为牙科诊所打造的智能管理系统。我们深知牙科诊所的运营挑战和患者需求，因此牙快约通过科技手段，为诊所提供高效的管理和流畅的患者体验。该系统集预约挂号、排队叫号等功能于一体，助力诊所提升工作效率，优化患者就诊体验。

          </p>
          <p class="fancy-text">
            <el-button type="primary" size="small" @click="toYakuaiyue">点击访问</el-button>
          </p>
          <h3>
            义齿管理系统
          </h3>
          <p class="fancy-text">
            我们为医疗机构提供的义齿管理系统，是一款革命性的产品。该系统支持义齿的入库、出库、查询、追踪等全流程管理，大大提升了工作效率和准确性。界面简洁明了，操作便捷易懂，支持多终端访问，为医疗机构带来高效便捷的新体验。

          </p>
          <h3>
            AI交易策略系统
          </h3>
          <p class="fancy-text">
            在投资领域，我们推出了领先的AI交易策略系统。该系统运用先进的AI算法，为投资者提供智能、高效的交易决策支持。帮助投资者精准捕捉市场机会，实现财富增长。AI交易策略不仅代表了技术的革新，更是投资者实现财富梦想的得力助手。
          </p>
          <h3>
            兽药易购：一键直达，健康守护
          </h3>
          <p class="fancy-text">
            兽药易购是我们打造的线上兽药销售平台。我们直连用户与优质兽药，为用户提供全面、快捷的购买服务。一键下单，健康即达，我们用心连接每一份对生命的关爱。兽药易购不仅是购物平台，更是您守护宠物和家畜健康的得力助手。
          </p>
          <p class="fancy-text">
            以上产品均体现了杭州阿尔法伽信息科技有限公司在科技创新和用户需求方面的深入理解和不懈追求。我们期待与您携手共创美好未来！
          </p>
        </el-col>
      </el-row>
    </div>
    <div id="在售服务" class="solve head-label">
      <div style="text-align: center;" class="font_size_24">
        在售服务
      </div>
    </div>
    <!-- 盒子 -->
    <div class="border_parent">
      <el-row>
        <el-col>
          <van-grid :border="false" :column-num="3">
            <van-grid-item style="width:100%" @click="onBuyProduct(product.productId)" v-for="(product, index) in productList">
              <van-card
                  :style="{width:isPhone?(screenWidth-60)+'px':(screenWidth-80)/3-40+'px'}"
                  num="1"
                  :price="product.deposit+'元'"
                  :desc="product.introduce"
                  :title="product.name"
              >
                <template #thumb>
                  <van-image
                      width="80"
                      height="80"
                      :src="getFileUrl(product.pic)"
                  />
                </template>
                <template #footer>
                  <van-button type="danger"  plain icon="gold-coin-o" @click="" size="small">购买</van-button>
                </template>
              </van-card>
            </van-grid-item>
          </van-grid>
        </el-col>
      </el-row>
    </div>
    <!-- 合作伙伴-->
    <div id="合作伙伴" class="solve head-label">
      <div style="text-align: center;" class="font_size_24">
        合作伙伴
      </div>
    </div>
    <div class="cooperation_parent">
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" style="width: 200px;height: 200px;">
          <div class="border" style="padding-bottom: 10px;">
            <div class="border_img_parent">
              <img src="../assets/qrcode_zju.edu.png" class="img_border"/>
            </div>
            <div class="border_title">浙江大学</div>
          </div>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" style="width: 200px;height: 200px;">
          <div class="border" style="padding-bottom: 10px;">
            <div class="border_img_parent">
              <img src="../assets/qrcode_zust.edu.png" class="img_border"/>
            </div>
            <div class="border_title">浙江科技大学</div>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 关于我们-->
    <div id="关于我们" class="solve head-label">
      <div style="text-align: center;" class="font_size_24">
        关于我们
      </div>
    </div>
    <div class="border_parent">
      <el-row>
        <el-col>
          <h3>
            我们的服务
          </h3>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司致力于提供全方位的互联网服务，以满足不同行业的需求。在数字化时代，我们专注于为企业提供高效、低成本的解决方案，助力企业在互联网上实现推广和增长。
          </p>

           <p class="fancy-text">
             我们提供的服务包括但不限于：微信小程序开发与维护、广告投放策略制定与执行、市场营销方案策划与实施等。这些服务旨在帮助企业实现数字化转型，提升品牌曝光度，扩大市场份额。
             特别地，在数字医疗领域，我们深知行业对于高效、稳定、安全的系统需求。因此，我们致力于提供定制化的数字医疗解决方案，助力医疗机构实现经济高效的运营。通过我们的服务，医疗机构可以更专注于核心业务，提升患者体验，优化内部管理。          </p>
          <p class="fancy-text">
            此外，我们还提供代运营服务，为客户解决日常运营中的繁琐问题。我们的专业团队将负责运营推广的各个环节，从策划、执行到优化，让您更专注于核心业务，轻松应对市场竞争。
          </p>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司不仅提供软件产品，更是您迈向数字化时代的好伙伴。我们期待与您携手共进，共创美好未来！
          </p>

          <h3>
            公司使命
          </h3>
          <p class="fancy-text">
            杭州阿尔法伽信息科技有限公司始终坚守“用科技连接医患，共享健康微笑”的核心理念。我们致力于通过创新的技术和卓越的服务，推动数字医疗运营实现智能化、高效化，同时优化患者的就医体验，使之更加流畅、便捷。我们坚信，技术的力量能够助力每一位客户在数字化时代实现业务增长和成功，共同迈向口腔健康的美好未来。
          </p>

          <h3>
            核心价值观
          </h3>
          <p class="fancy-text">
            客户至上、创新驱动、品质保证、团队协作、诚信为本。
                  </p>
        </el-col>
      </el-row>
    </div>


  </div>
</template>
<script>
  import {listAllProduct} from "@/api/product";

  export default {
    name: 'Index',
    data() {
      return {
        sliders: [],
        carousel_height: '550px',
        screenWidth: 0,
        isPhone: false,
        productList: [],
      }
    },
    methods:{
      loadAllProduct(){
        listAllProduct().then(res=>{
          if(res.code == 200){
            this.productList = res.data;
            console.log("productList", this.productList)
          }
        })
      },
      onBuyProduct(productId){
        this.$router.push("/buyProduct?id="+productId)
      },
      toYakuaiyue(){
        window.open('https://yakuaiyue.com')
      },
      updateScreenWidth() {
        this.screenWidth = window.innerWidth;
      },
      menuSeen(){
        // 屏幕尺寸
        let screenHeight=document.body.clientWidth;
        if(screenHeight>415){
          this.isPhone=false;
        }else{
          this.isPhone=true;
        }
      },
    },
    mounted() {
      this.sliders = [
        {
          img: require("../assets/images/yakuaiyue.jpg")
        },
        {
          img: require("../assets/images/yichi.jpg")
        },
        {
          img: require('../assets/images/aicelue.jpg')
        },
        {
          img: require('../assets/images/shouyao.jpg')
        },
      ]
        this.updateScreenWidth();
      window.addEventListener('resize', this.updateScreenWidth);
      this.menuSeen()
      this.loadAllProduct()
    },
    computed: {
      //计算高度
      computeHeight() {
        let screenHeight = document.body.clientWidth;
        if (screenHeight > 415) {
          return '450px';
        } else {
          return '250px';
        }
      },
      computeArrowImg() {
        let screenHeight = document.body.clientWidth;
        if (screenHeight > 450) {
          return require('../assets/images/arrow.png');
        } else {
          return require('../assets/images/arrow_down.png');
        }
      }

    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateScreenWidth);
    },

  }
</script>

<style scoped>
  .border_parent {
    width: 90%;
    margin: auto;
  }

  .slider_img {
    height: 450px;
    width: 100%;
  }

  .solve {
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 10px
  }

  .border {
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    height: auto;
    margin: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
  }

  .border_one {
    margin-left: 0;
  }

  .border_img_parent {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .product_mobile_content {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .product_mobile_img {
    width: 50%;
  }

  .product_mobile_title {
    font-weight: bold;
    text-align: center;
  }

  .product_pc_one {
    text-align: center;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .font_size_30 {
    font-size: 1.875em;
  }

  .product_font_style {
    font-size: 1.125em;
  }

  .product_pc_img {
    width: 100%
  }

  .product_pc_img_div {
    width: 50%;
    float: left;
    display: flex;
    align-items: center;
  }

  .product_pc {
    display: flex;
    width: 90%;
    margin: 20px auto;
    backg: #F2F6FC;
    align-items: center;
  }

  .product_spec_title {
    margin-top: 30px;
    margin-bottom: 10px
  }

  .product_spec_parent {
    display: flex;
    width: 90%;
    margin: 20px auto;
    align-items: center;
    height: auto
  }

  .cooperation_parent {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 20px auto;
    align-items: center;
    height: auto
  }

  .border_spec {
    border-radius: 6px;
    border: 1px solid #EBEEF5;
    float: left;
    width: 25%;
    height: auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  .about_me_p {
    font-size: 14px;
    font-weight: normal;
    padding: 0 20px;
    margin: 10px;
  }

  .border_title {
    font-family: "PingFang SC";
    margin-top: 10px;
    font-size: 1.125em;
    font-weight: bold;
    text-align: center;
  }

  .border_content_one {
    margin-top: 20px;
    font-size: 1.125em;
    text-align: center;
  }

  .border_content_two {
    margin-top: 5px;
    font-size: 1.125em;
    margin-bottom: 25px;
    text-align: center;
  }

  .img_border {
    margin-top: 15px;
    width: 80px;
    height: 80px
  }

  .border_spec_inner_one {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
    height: 70px;
  }

  .border_spec_content_one {
    margin-top: 15px;
    font-size: 1.125em;
    float: left;
    text-align: center;
  }

  .border_spec_inner_last {
    margin-top: 15px;
    font-size: 1.125em;
    float: left;
    margin-bottom: 25px;
    text-align: center;
  }

  .cooperation {
    margin-top: 30px;
    width: 100%;
  }

  .about_me {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    width: 100%;
  }

  .product_mobile {
    width: 90%;
    align-items: center;
    margin: 20px auto;
    backg: #F2F6FC;
  }

  .product_mobile_img_div {
    width: 50%;
    height: auto;
  }

  .product_mobile_content_one {
    margin-top: 20px;
    text-align: center;
  }

  .product_mobile_content_two {
    margin-top: 10px;
    text-align: center;
  }

  /* 屏幕设备适配 max-device-width: 400px */
  @media screen and (max-device-width: 415px) {
    .slider_img {
      height: 250px;
      width: 100%;
    }


    .border_parent {
      display: flex;
      width: 90%;
      margin: auto;
      height: auto;
      flex-direction: column;
    }

    .border {
      border-radius: 6px;
      border: 1px solid #EBEEF5;
      float: left;
      width: 100%;
      height: auto;
      margin: 10px 0 auto;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
      /*backg: aquamarine;*/
    }

    .border_img_parent {
      margin-top: 0;

    }

    .product_mobile {
      width: 90%;
      align-items: center;
      margin: 20px auto;
      height: auto;
      backg: #F2F6FC;
    }

    .product_mobile_content {
      width: 100%;
      margin-top: 15px;
    }

    .product_mobile_content_one {
      margin-top: 5px;
    }

    .product_mobile_content_two {
      margin-top: 5px;
    }

    .product_mobile_img_div {
      display: flex;
      width: 100%;
      height: auto;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 15px;
    }

    .product_mobile_img {
      float: left;
    }

    .product_mobile_title {
      font-weight: bold;
    }

    .product_pc_one {
      width: 100%;
      margin-top: 15px;
    }

    .font_size_30 {
      font-size: 1.25em;
    }

    .product_font_style {
      font-size: 1em;
    }

    .product_pc_img {
      height: 280px;
      width: 320px;
    }

    .product_pc_img_div {
      width: 100%;
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }

    .product_pc {
      display: flex;
      width: 90%;
      height: auto;
      margin: 20px auto;
      backg: #F2F6FC;
      flex-direction: column;
    }

    .head-label {
      display: flex;
      margin-top: 20px;
    }

    .product_spec_parent {
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
      height: auto;
      flex-direction: column;
    }

    .border_spec {
      float: left;
      height: auto;
      margin: 8px;
      width: 100%;
      border-radius: 6px;
      border: 1px solid #EBEEF5;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }


    .border_title {
      font-family: "PingFang SC";
      margin-top: 5px;
      font-size: 1.125em;
      font-weight: bold;
    }

    .border_content_one {
      margin-top: 5px;
      font-size: 1.125em;
    }

    .border_content_two {
      margin-top: 5px;
      font-size: 1.125em;
      margin-bottom: 8px;
    }

    .img_border {
      margin-top: 8px;
      width: 80px;
      height: 80px
    }

    .border_spec_inner_one {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 8px;
      /*backg-color: aqua;*/
      height: 70px;
    }

    .border_spec_content_one {
      margin-top: 5px;
      font-size: 1.125em;
      float: left;
    }

    .border_spec_inner_last {
      /*backg-color: bisque;*/
      margin-top: 5px;
      font-size: 1.125em;
      float: left;
      margin-bottom: 8px;
    }

    .about_me {
      display: flex;
      margin-top: 10px;
      width: 100%;
    }
  }

  .border_spec_img {
    width: 75px;
    height: 55px;
    align-self: center
  }

  .font_size_24 {
    margin-top: 10px;
    font-size: 1.75em;
  }

  .fancy-text {
    padding-left: 0px;
    font-family: 'Arial', sans-serif; /* 设置字体 */
    color: #336699; /* 设置颜色 */
    font-size: 14px; /* 设置字体大小 */
    text-indent: 28px;
    /* 其他样式设置 */
  }
</style>
